/************************************** MEDIA *******************************/
@media (max-width: 599px) {
  .hidden-xs {display: none !important;}
  .text-left{text-align: center !important;}
  .text-right{text-align: center !important;}
	.border-xs-none{ border: 0px !important;}
  .carousel-control-next, .carousel-control-prev {align-items: end; width: 12%;}
  #image-hover .contenido{opacity: 1;}

  #home-slide h1{font-size: 40px !important; }
  #home-slide .min-h-350{min-height: 300px !important; }
  #home-slide .py-7 {padding-top: 0px !important; padding-bottom: 70px !important;}
  #home-slide img.img-producto{max-width: 215px !important;}
  .cont-nombre-producto p {
    font-size: 40px;
    text-align: center;
}
.product-single__title {
    text-align: center ;
}
    .product-form__item--quantity {
        width: 100% !important;
    }
    .product-single__price-container {
    text-align: center;
}

}
@media (min-width: 600px) and (max-width: 991px) {
  .hidden-sm {display: none !important;}
  .text-left{text-align: center !important;}
  .text-right{text-align: center !important;}
  .border-sm-none{ border: 0px !important;}
  .carousel-control-next, .carousel-control-prev {align-items: end; width: 12%;}
  #image-hover .contenido{opacity: 1;}

  #home-slide h1{font-size: 40px !important; }
  #home-slide .min-h-350{min-height: 300px !important; }
  #home-slide .py-7 {padding-top: 0px !important; padding-bottom: 70px !important;}
  #home-slide img.img-producto{max-width: 215px !important;}


}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {display: none !important;}
  .border-md-none{ border: 0px !important;}

}
@media (min-width: 1200px) {
  .hidden-lg {display: none !important;}
  .border-lg-none{ border: 0px !important;}
  .container {max-width: 1300px !important;}

}
